import React from "react"
import Layout from "../components/Layout"

const ContactPage = () => {
  return (
    <Layout>
      <h2>contact page</h2>
    </Layout>
  )
}

export default ContactPage
